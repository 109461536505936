@import 'highlightColors/highlightsCombinations.css';

/*Hide learnosity palette, pointer and remove button*/
.bluebook-player :global(.lrn-annotations-c-popover),
.bluebook-player
  :global(
    .lrn-annotations-c-popover .lrn-annotations-c-popover__paletteWrapper
  ),
.bluebook-player
  :global(.lrn-annotations-c-popover .lrn-annotations-c-popover__pointer),
.bluebook-player
  :global(
    .lrn-annotations-c-popover .lrn-annotations-c-button--removeHighlight
  ) {
  @apply invisible animate-none w-[286px] h-[50px];
}

.bluebook-player:global(
    .with-annotation.can-annotate .lrn-annotations-c-popover
  ),
.bluebook-player:global(.can-annotate .lrn-annotations-c-popover),
.bluebook-player:global(.selected-annotation .lrn-annotations-c-popover) {
  @apply visible;
}

.bluebook-player :global(.lrn-annotations-c-popover) {
  @apply bg-none border-none shadow-none;
}

.bluebook-player :global(.one-column) {
  @apply flex flex-row;
}
.bluebook-player :global(.one-column > div:first-child) {
  @apply grow;
}

.bluebook-player
  :global(
    .bluebook-player-inner-container
      #learnosity_assess
      .learnosity-item.with-two-columns
      .two-columns.left-column:has(.notes-column)
      .lrn.lrn_feature.lrn_sharedpassage
  ),
.bluebook-player
  :global(
    .bluebook-player-inner-container
      #learnosity_assess
      .learnosity-item.with-two-columns
      .two-columns.left-column:has(.notes-column)
      > div[style*='height']
  ) {
  @apply grow !w-auto pt-12 !my-0;
}

.bluebook-player
  :global(
    .bluebook-player-inner-container
      #learnosity_assess
      .learnosity-item.with-two-columns
      .two-columns.left-column:has(.notes-column)
      .left-column-expander
  ) {
  @apply absolute left-[calc(100%-46px)];
}

.bluebook-player
  :global(
    .bluebook-player-inner-container
      #learnosity_assess
      .learnosity-item.with-two-columns
      .two-columns.left-column:has(.notes-column)
  ) {
  @apply flex;
}
