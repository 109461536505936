:global(
    .chameleon
      .bluebook-player-inner-container
      .special-characters-popup
      .popover__content
  ) {
  padding: 0 !important;
  border-radius: 2px !important;
  border: 1px solid var(--color-bluebook-gray2) !important;
  overflow: visible !important;
}

:global(
    .chameleon
      .bluebook-player-inner-container
      .special-characters-popup
      .popover__content
      div[role='presentation']:not(:has(& > .special-characters-content))
  ) {
  max-height: inherit;
  width: inherit;
}

:global(
    .chameleon
      .bluebook-player-inner-container
      .special-characters-popup
      .popover__content
      .special-characters-content
  ) {
  display: grid;
  grid-template-rows: 4.2rem 1fr 2.75rem;
  max-height: inherit;
  width: inherit;
}

:global(
    .chameleon
      .bluebook-player-inner-container
      .special-characters-popup
      .popover__content
      .special-characters-content
      > div
  ) {
  width: inherit;
}
