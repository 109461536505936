.tabs_col,
.question_col {
  @apply flex-1;
  -webkit-text-size-adjust: none;
}

.tabs_col :global .lrn_response_wrapper {
  /* overwrite min height set by Learnosity JS to we can have a correct size of the item when
       formula Editor dialog is open.
    */
  min-height: auto !important;
}

.tabs_col :global .lrn-formula-keyboard {
  /* default width of the learnosity formula editor is not very mobile friendly  */
  min-width: 300px !important;
}

.tabs_col
  :global
  .lrn-formula-keyboard-menu-right
  .lrn-formula-keyboard-menu-container {
  /* hide help dialog as it does not fit SPP layout */
  display: none !important;
}

.header {
  @apply desktop:sticky;
  top: 0;
  z-index: 1;
  transition: transform 0.4s;
}

.scroll_up {
  position: sticky;
  transform: none;
}

.scroll_down {
  transform: translate3d(0, -100%, 0);
}

.tabs_col {
  @apply desktop:ml-4 desktop:-mr-8 desktop:sticky desktop:-mt-8 desktop:w-6/12;
  align-self: flex-start;
  top: 130px;
}

.question_col {
  @apply p-4 desktop:p-8 desktop:mr-4 bg-white desktop:w-6/12;
}

.question_col :global .lrn_feature.lrn_sharedpassage .lrn-viewport {
  overflow: visible;
  height: auto;
}

/* Hide Tips, Answer boxes, Clozedropdowns and Video Players on the rendered content for SPP/Labs */
.question_col :global .lrn_formulaessayV2,
.question_col :global .lrn_longtextV2,
.question_col :global .lrn_clozedropdown,
.question_col :global .lrn_mp4_video {
  @apply hidden;
}

.question_col :global .MathJax_Preview * {
  display: none;
}
.question_col :global .MathJax_Preview::before {
  content: '[content loading]';
}
.question_col :global math {
  position: absolute;
  left: -10000px;
}

.spp_identifier__wrapper {
  @apply flex;
  padding-left: 10px;
  justify-content: center;
  flex: 10;
}

.spp_identifier_teacher_assigned {
  @apply flex items-center justify-center w-44 h-16 bg-yellow-100 font-normal;
  width: 20rem;
  border: 1px solid #e2e2e2;
}

.spp_identifier_student_sandbox {
  @apply flex items-center justify-center w-44 h-16 bg-blue-200 font-normal;
  width: 20rem;
  border: 1px solid #e2e2e2;
}

.spp_identifier_teacher_review,
.spp_identifier_student_review {
  @apply flex items-center justify-center w-44 h-16 bg-green-100 font-normal;
  width: 20rem;
  border: 1px solid #e2e2e2;
}

.spp_identifier_teacher_preview {
  @apply flex items-center justify-center w-44 h-16 bg-teal-100 font-normal;
  width: 20rem;
  border: 1px solid #e2e2e2;
}

.spp_info_icon svg path {
  fill: transparent;
  stroke: #000000;
}

.tabs_content {
  @apply bg-white p-3 desktop:p-8;
  margin-top: -35px;
  min-height: 680px;
}

/* Code Editor styling for Answer textbox */
:global .code-editor-textbox .lrn-toolbar,
:global .code-editor-textbox .lrn_texteditor_editable p,
:global .code-editor-textbox .CodeMirror-gutter-wrapper,
:global .code-editor-textbox .CodeMirror-gutters {
  display: none;
}

:global .code-editor-textbox .CodeMirror.cm-s-default {
  margin-bottom: 0 !important;
}

:global .code-editor-textbox .CodeMirror-sizer {
  margin-left: 0 !important;
}

:global .code-editor-textbox .CodeMirror-lines {
  min-height: 80px !important;
  padding: 1rem !important;
}

:global
  .code-editor-textbox
  .lrn_texteditor_editable.lrn_editor_area.lrn_longtextinput {
  padding: 0;
}
/* END of Code Editor styling for Answer textbox */

.tabs_content :global .LoadingSpinner__wrapper {
  min-height: 30em;
}

.title {
  @apply flex flex-1 desktop:block p-4 desktop:p-0 border-b border-gray-300 desktop:border-0;
}

.tab {
  @apply bg-none border-0 my-0 mx-1 text-14 text-blue-700 font-heading desktop:text-16 p-5 desktop:py-5 desktop:p-10 desktop:bg-gray-300 desktop:mx-3;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
}

.tab_bordered {
  @apply pb-0.8 mx-1 pt-3 desktop:pt-0;
  position: relative;
}

.tab_bordered_active::after {
  @apply border-b-4 border-b-blue-700;
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
}

.mui_tabs {
  @apply mobile:w-full mobile:max-w-full;
}

.tabs {
  @apply border-r border-gray-300 flex flex-1 flex-nowrap w-10/12 desktop:border-0 desktop:w-full pb-2;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.tabs::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.tab:first-child {
  margin-left: 0;
}

.tab:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tab.active {
  @apply bg-white text-black;
}
.tab.answered {
  @apply text-purple-600;
}

.tips_nav {
  @apply flex items-center border border-solid rounded-full border-gray-300 p-3 bg-white;
  width: auto;
  float: right;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.06),
    0 1px 4px rgba(23, 23, 37, 0.15);
  position: sticky;
  bottom: 20px;
}

.small_header {
  @apply mt-0 font-heading text-20 desktop:text-16 mb-8 pb-6 border-solid border-b border-gray-300;
  font-weight: 400;
}

.small_header_v2 {
  @apply mt-0 font-heading text-14 desktop:text-16 pl-3 mb-8 pb-6 border-solid border-b border-gray-300;
  font-weight: 400;
}

.tip_overlay {
  @apply flex items-center border border-solid border-blue-500 p-3 bg-blue-100 mb-8 text-blue-600 rounded-sm;
  width: 100%;
  justify-content: center;
  min-height: 140px;
  cursor: pointer;
  border-radius: 4px;
}

.tip_overlay.disabled {
  @apply border-gray-500 p-3 bg-gray-100 text-gray-600 p-10;
  cursor: default;
}

.video_tab {
  max-width: 720px;
  margin: auto;
  min-height: 50vh;
}

.text {
  @apply border-gray-500 p-3 bg-gray-100 text-gray-900 p-10;
  line-height: 1.5;
}

.nav {
  @apply desktop:py-8 desktop:px-10 desktop:flex bg-white;
  align-items: center;
  align-self: flex-start;
  z-index: 1;
}

.main_header {
  @apply text-16 desktop:text-18;
  line-height: 1.1;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.offscreen {
  transform: scale(0);
  position: absolute;
}

.item_is_loading :global .lrn-overview > div {
  position: absolute;
  left: -100000px;
  height: 0;
  overflow: hidden;
}

.item_is_loading :global .lrn-overview > div:first-child {
  position: static;
  left: auto;
  height: auto;
  overflow: visible;
}

.item_is_loading :global .col-xs-12 > div {
  height: auto !important;
}

.student_filter {
  @apply flex-1;
  /*
        can't convert to Tailwind styles because they use rem and
        have no provision for em
    */
  max-width: 18em;
  min-width: 8em;
}

.student_filter .autocomplete__dropdown {
  width: inherit;
}
